import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { ellipsis } from 'style/global.styles';

export const root = css`
  display: inline-flex;
  align-items: center;
  padding: ${spacing(6)} ${spacing(16)};
  ${font.size.xsmall};
  ${font.weight.bold};
  border-radius: ${spacing(3)};
  border: 1px solid hsl(var(--color-borderDark-5));
  transition: border 0.2s;
  margin: ${spacing(4)} ${spacing(8)} ${spacing(4)} 0;
  max-width: 100%;
  color: hsl(var(--color-grayscale-1));

  &:last-of-type {
    margin-right: 0;
  }

  span {
    ${ellipsis}
  }
`;

export const rootLight = css`
  ${root};
  border: 1px solid hsl(var(--color-borderLight-8));
  color: hsl(var(--color-borderBlack-2));
`;

export const rootDownload = css`
  ${root};
  color: hsl(var(--color-grayscale-1));

  &:hover {
    border: 1px solid hsl(var(--color-border-7));
    cursor: pointer;

    path {
      fill: hsl(var(--color-border-7));
    }
  }
`;

export const rootDownloadLight = css`
  ${rootLight};
  border: 1px solid hsl(var(--color-borderDark-7));

  &:hover {
    border: 1px solid hsl(var(--color-borderBlack-2));
    cursor: pointer;

    path {
      fill: hsl(var(--color-borderBlack-2));
    }
  }
`;

export const disabled = css`
  ${root};
  opacity: 0.6;

  &:hover {
    cursor: default;
  }
`;

export const disabledLight = css`
  ${disabled};
  color: hsl(var(--color-text-1));
`;

export const downloadIcon = css`
  width: ${spacing(16)};
  height: ${spacing(16)};
  min-width: ${spacing(16)};
  margin-right: ${spacing(8)};

  path {
    transition: fill 0.2s;
  }
`;

export const deleteIcon = css`
  ${downloadIcon};
  margin-right: 0;
  margin-left: ${spacing(8)};

  path {
    fill: hsl(var(--color-grayscale-6));
  }

  &:hover {
    cursor: pointer;

    path {
      fill: hsl(var(--color-border-7));
    }
  }
`;

export const deleteIconLight = css`
  ${deleteIcon};

  &:hover {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;
